<template>
    <highcharts class="chart h-full w-full chart-wrap" :options="options" ref="chartId" id="chartId" />
</template>

<script>
import { state, actions } from '@/store'

    export default {
        props: ['data'],
        data: function(){
            return {
                chart: null
            }
        },
        computed: {
            options() {
                return {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        margin:[0,0,0,0]
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        enabled: false
                    },
                    credits:{
                        enabled: false
                    },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ 
                            outerRadius: '115%',
                            innerRadius: '105%',
                            borderWidth: 0,
                            backgroundColor: this.mode == 'whitemode' ? '#F0EFEF' : '#1F232F'
                        },
                        { 
                            outerRadius: '100%',
                            innerRadius: '90%',
                            borderWidth: 0,
                            backgroundColor: this.mode == 'whitemode' ? '#F0EFEF' : '#1F232F'
                        }]
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },
                    series: [
                        {
                            name: 'a',
                            data: [ {
                                color: (this.data.actual > this.data.prev) ? '#7beeb3' : '#FF6154',
                                radius: '115%',
                                innerRadius: '105%',
                                y: (this.data.actual > this.data.prev) ? this.data.actual : this.data.prev
                                //y: 0
                            },
                            {
                                color: this.mode == 'whitemode' ? '#F0EFEF' : '#1F232F',
                                radius: '115%',
                                innerRadius: '105%',
                                y: (this.data.actual > this.data.prev) ?  this.data.prev+.3 : this.data.actual+.3
                                //y: 0
                            },
                            {
                                color: '#00B5A1',
                                radius: '115%',
                                innerRadius: '105%',
                                y: (this.data.actual > this.data.prev) ? this.data.prev : this.data.actual
                            }]
                        },
                        {
                            name: 'b',
                            data: [{
                                color: '#B4BCCE',
                                radius: '100%',
                                innerRadius: '90%',
                                y: this.data.prev
                            }]
                        }
                    ],
                    responsive: {

                    }
                }
            },
            mode(){
                return state.mode
            }
        },
        mounted(){
            const chartI = this.$refs.chartId;
            const chart = chartI.chart;
            setTimeout(() => {
                chart.reflow();
            }, 200);

        }
    }
</script>
<style>
    .chart-wrap{
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap svg{
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap .highcharts-container{
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap>div{
        height: 100% !important;
        width: 100% !important;
    }
</style>
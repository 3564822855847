<template>
    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6">

            <div class="h-12 flex flex-row">

                <div class="h-full w-10/12 flex flex-row">

                    <div class="h-full w-6/6 flex flex-col justify-center items-start px-2">
                        <span class="text-2xl text-darkgreen">**{{$t('name')}}**</span>
                    </div>

                </div>

                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <i class="mdi mdi-dots-vertical text-gray text-xl"></i>
                </div>

            </div>

            <div class="h-12 mt-6 rounded-lg p-1 bg-lightgray flex flex-row overflow-hidden">

                <div class="h-full w-full rounded-lg flex flex-row justify-center items-center bg-lightgray" @click="toTops()">
                    <i class="mdi mdi-filter-variant text-purple"></i>
                </div>

            </div>

            <div class="h-12 mt-2 rounded-lg p-1 bg-lightgray flex flex-row overflow-hidden">

                <div class="h-full w-full rounded-lg flex flex-row bg-lightgray" @click="toTops()">
                    <div class="h-full w-1/4 flex flex-row justify-center items-center">
                        <i class="mdi mdi-account-tie text-purple text-xl"></i>
                    </div>
                    <div class="h-full w-3/4 flex flex-row justify-start items-center overflow-y-auto">
                        <span class="text-gray text-sm m-1">Por favor recordar fin de mes</span>
                    </div>
                </div>

            </div>

            <div class="bg-lightgray rounded-lg flex flex-row mt-6" :class="{'h-40': !facturation, 'h-auto': facturation}" @click="activeFacturation()">

                <div class="h-full w-full flex flex-row">

                    <div class="h-full w-full flex flex-col p-2">

                        <div class="h-auto flex flex-row justify-start items-center">
                            <span class="text-darkgreen text-xl m-1">{{$t('deliveryNotes')}}</span>
                        </div>

                        <div class="flex-1 flex flex-col justify-between items-start py-2">

                            <div class="h-auto w-full flex flex-row justify-between items-center px-1">
                                <span class="text-sm text-darkgreen">{{$t('billing')}}</span>
                                <span class="text-sm text-darkgreen">15.942€</span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-1">
                                <span class="text-sm text-darkgreen">{{$t('meters')}}</span>
                                <span class="text-sm text-darkgreen">680m2</span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center px-1">
                                <span class="text-sm text-darkgreen">{{$t('averagePrice')}}</span>
                                <span class="text-sm text-darkgreen">842€</span>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="h-32 mt-6 flex flex-col">

                <div class="h-auto flex flex-row justify-start items-center">
                    <span class="text-gray text-sm m-1 font-medium">Ú{{$t('lastSevenDays')}}</span>
                </div>

                <div class="flex-1">
                    <columnchart></columnchart>
                </div>

            </div>

            <div class="h-6"></div>

        </div>

    </div>
</template>

<script>
    import { state } from '@/store';
    import solidgauge from '../../components/solidgauge.vue';
    import columnchart from '../../components/columnchart.vue';

    export default {
        name: 'representante',
        components:{
            solidgauge,
            columnchart
        },
        data(){
            return{
                facturation: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY')
            }
        },
        methods:{
            toTops(){
                this.$router.push('tops')
            },
            activeFacturation(){
                this.facturation = !this.facturation
            }
        },
        computed: {
            user() {
                return state.user;
            },
        },
    }
</script>
